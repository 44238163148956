import liff from '@line/liff'

export const line = () => {
  liff.init({liffId: '2006496323-znKxGkXx'}).then(() => {
  }).catch(() => {
  })

}

export const canActive = liff.ready.then(() => {
  return liff.isInClient()
 })