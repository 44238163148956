<template>
  <v-app>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({
    //
  }),
};
</script>


<style lang="scss" >
@import url('https://fonts.googleapis.com/css2?family=Itim&display=swap');

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Itim", cursive;
  /* font-family: 'Noto Sans Thai', sans-serif; */
}
html {
  overflow-y: auto !important;
}
</style>